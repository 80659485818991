<template><router-view> </router-view></template>

<script>
import HomeView from "./views/HomeView.vue";
export default {
  components: {
    HomeView,
  },
  mounted(){
    document.title = "Emir Sahin"
  }
};
</script>
